<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <!--
      <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li>
      -->
    </ul>


    <!-- Right navbar links -->
    <!--
    <ul class="navbar-nav ml-auto"></ul>-->
    <!-- Navbar Search -->
    <!--
      <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>
      -->


    <!-- Notifications Dropdown Menu -->
    <!--
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
-->
    <!--
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
    -->


    <!--
      <Message></Message>
  <UserInfo></UserInfo>
    </ul>
-->
    <ul class="navbar-nav" v-for="(widget,index) in widgets" :key="widget.id" v-bind:class="{'ml-auto':(index===0)}">
      <li class="nav-item">
        <component v-bind:is="widget.widgetComponent" v-bind:widget="widget" :key="Math.random()"></component>
      </li>
    </ul>

    <ul class="navbar-nav" v-show="right_side_toggle_show">
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>

export default {
  data() {
    for (var key in this.$microlink.sides.navbar.widgets) {
      if (this.$microlink.widgets[this.$microlink.sides.navbar.widgets[key].widget] == undefined) {
        continue;
      }

      let widget_type = this.$microlink.widgets[this.$microlink.sides.navbar.widgets[key].widget].widget.type;
      if (this.$microlink.widgetComponents[widget_type] != undefined) {
        // 組件類型
        this.$microlink.sides.navbar.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];
        // 操作記錄
        this.$microlink.sides.navbar.widgets[key].history = [];
      }
    }

    return {
      widgets: this.$microlink.sides.navbar.widgets,
      right_side_toggle_show: this.$microlink.sides.rightside.widgets.length > 0
    }
  }
}
</script>

<style></style>