<template>
  <div class="modal fade align-items-center justify-content-center" id="modal-ml-login" data-backdrop="static">

    <div class="modal-dialog modal-sm">
      <h4 class="modal-title">{{ information.title }}</h4>

      <div class="modal-content">
        <div class="modal-body">
          <p class="login-box-msg" v-show="login_message != ''">{{ login_message }}</p>

          <form action="" method="post">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="帳號" v-model="account">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="密碼" v-model="password" autocomplete="true">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>


            <div class="input-group mb-3" v-show="show_verification">
              <input type="text" class="form-control" placeholder="驗證碼" v-model="verification_code">
              <div class="input-group-prepend">
                <span class="input-group-text"><img class="verification-code" v-bind:src="verification_code_url" /></span>
              </div>
              <div class="input-group-prepend">
                <div class="input-group-text refresh" @click="refresh_verification_code">
                  <span class="fas fa-redo"></span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <div class="icheck-primary" v-show="show_remember">
                  <input type="checkbox" v-model="remember">
                  <label for="remember" @click="remember_click">
                    記住我
                  </label>
                </div>
              </div>
              <!-- /.col -->
              <div class="col-4">
                <button type="button" class="btn btn-success btn-block" @click="check_login">登 入</button>
              </div>
              <!-- /.col -->
            </div>
            <!--
            <div class="text-center">
              <p class="extra">
                <a href="forgot-password.html">忘記密碼</a> - <a href="register.html" class="text-center">註冊</a>
              </p>
            </div>
            -->
          </form>
          <!--
          <div class="social-auth-links text-center mb-3">
            <p>- 快速登入 -</p>
            <a href="#" class="btn btn-block btn-primary">
              <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
            </a>
            <a href="#" class="btn btn-block btn-danger">
              <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
            </a>
          </div>
          -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {

    this.$microlink.information.remember_days = parseInt(this.$microlink.information.remember_days);
    if (isNaN(this.$microlink.information.remember_days)) {
      this.$microlink.information.remember_days = 0;
    }
    
    // 判斷是否顯示驗證碼
    let show_verification = (this.$microlink.information.captcha_check != undefined && this.$microlink.information.captcha_check != '');
    return {
      verification_code_url: this.$microlink.base_api_url + this.$microlink.information.captcha_image,
      information: this.$microlink.information,
      show_verification: show_verification,
      login_message: '',
      account: '',
      password: '',
      verification_code: '',
      remember: false,
      show_remember: (this.$microlink.information.remember_days > 0)
    }
  },
  methods: {
    refresh_verification_code: function () {
      this.verification_code_url = this.$microlink.base_api_url + this.$microlink.information.captcha_image + '?v=' + Math.random().toString().substring(2);
    },
    check_login: function () {
      this.login(this.account, this.password, this.verification_code);
    },

    /**
     * 登入
     */
    login: function (account, password, verification_code) {
      let api_url = this.$microlink.base_api_url + '/user/login';
      let $this = this;

      this.$microlink.api_post(api_url, {
        'account': account,
        'password': password,
        'verification_code': verification_code
      }, true, function (response) {
        if (response.status == 'success') {

          // 如有鉤選「記住我」，則將登入資訊存入 localStorage
          if ($this.remember && response.remember_token != undefined && response.remember_token != '') {
            // 將帳號+密碼+目前時間透過:::間隔串成一個字串後再 base64 編碼
            let remember = response.remember_token; // $this.$microlink.base64_encode(account + ':::' + password + ':::' + Date.now());
            localStorage.setItem('remember', remember);
          }

          location.reload();
        }
        else {
          $this.login_message = response.message;

          // 讓整個 class="card" 的區塊能夠有左右振動效果
          document.querySelector('.modal-content').classList.add('shake');
          setTimeout(function () {
            document.querySelector('.modal-content').classList.remove('shake');
          }, 500);

        }
      })
    },

    /**
     * 記住我點擊事件
     */
    remember_click: function () {
      this.remember = !this.remember;
    }
  }
}
</script>

<style scoped>
#modal-ml-login {
  background-color: #5382b5;
  background-image: url('../assets/img/login-background.png');
  display: none;
}

.modal-title {
  color: white;
  text-align: center;
}

p.login-box-msg {
  color: red;
}

img.verification-code {
  height: 24px;
}

div.refresh {
  margin-left: 3px;
  cursor: pointer;
}

/**
 * 左右振動效果
 */
.modal-content.shake {
  animation: shake 0.5s;
}
</style>