<template>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
    <div v-for="widget in widgets" :key="widget.id"
      v-bind:class="['col-' + widget.mobile, 'col-sm-' + widget.pad, 'col-md-' + widget.pad, 'col-lg-' + widget.desktop]">
      <component v-bind:is="widget.widgetComponent" v-bind:widget="widget" :key="Math.random()"></component>
    </div>
  </aside>
  <!-- /.control-sidebar -->
</template>

<script>
export default {
  data() {
    for (var key in this.$microlink.sides.rightside.widgets) {
      if (this.$microlink.widgets[this.$microlink.sides.rightside.widgets[key].widget] == undefined) {
        continue;
      }

      let widget_type = this.$microlink.widgets[this.$microlink.sides.rightside.widgets[key].widget].widget.type;
      if (this.$microlink.widgetComponents[widget_type] != undefined) {
        // 組件類型
        this.$microlink.sides.rightside.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];
        // 操作記錄
        this.$microlink.sides.rightside.widgets[key].history = [];
      }
    }

    return {
      widgets: this.$microlink.sides.rightside.widgets
    }
  }
};
</script>

<style></style>