<template>
  <nav class="mt-2">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
      <Item v-for="item in items" v-bind:key="item" v-bind:item="item"></Item>
    </ul>
  </nav>
</template>

<script>
import Item from "./SidebarMenu-Item.vue"
export default {
  components: {
    Item
  },
  mounted() {
    // 展開當前頁面的父層，當前頁面的項目會被加上 router-link-active 類別
    this.$el.querySelectorAll('.router-link-active').forEach(function (item) {
      item.parentNode.parentNode.classList.add('menu-open');
      item.parentNode.parentNode.parentNode.classList.add('menu-open');
      item.parentNode.parentNode.style.display = 'block';
    });
  },
  data() {
    this.items = this.$microlink.menu;
    return { 'items': this.items }
  },  
}
</script>

<style scoped>

</style>