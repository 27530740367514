<template>
  <footer class="main-footer">
    <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io/">AdminLTE.io</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.1.0
    </div>
    <div v-for="widget in widgets" :key="widget.id"
      v-bind:class="['col-' + widget.mobile, 'col-sm-' + widget.pad, 'col-md-' + widget.pad, 'col-lg-' + widget.desktop]">
      <component v-bind:is="widget.widgetComponent" v-bind:widget="widget" :key="Math.random()"></component>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    for (var key in this.$microlink.sides.footer.widgets) {
      if (this.$microlink.widgets[this.$microlink.sides.footer.widgets[key].widget] == undefined) {
        continue;
      }

      let widget_type = this.$microlink.widgets[this.$microlink.sides.footer.widgets[key].widget].widget.type;
      if (this.$microlink.widgetComponents[widget_type] != undefined) {
        // 組件類型
        this.$microlink.sides.footer.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];
        // 操作記錄
        this.$microlink.sides.footer.widgets[key].history = [];
      }
    }

    return {
      widgets: this.$microlink.sides.footer.widgets
    }
  }
}
</script>

<style></style>