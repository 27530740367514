<template>
  <ml-navbar></ml-navbar>
  <ml-sidebar></ml-sidebar>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{ title }}</h1>
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">

        <div class="row">
          <div v-for="(widget, key) in widgets" :key="widget.id"
            v-bind:class="['col-' + widget.mobile, 'col-sm-' + widget.pad, 'col-md-' + widget.pad, 'col-lg-' + widget.desktop]">
            <component v-bind:is="widget.widgetComponent" v-bind:widget="widget"
              @widgetEvent="widget_event($event, key)" :key="Math.random()"></component>
          </div>
          <ml-login></ml-login>
        </div>
      </div>
    </section>
  </div>
  <ml-footer></ml-footer>
  <ml-controlsidebar></ml-controlsidebar>
</template>

<script>
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";
import Footer from "./Footer.vue";
import ControlSidebar from "./ControlSidebar.vue"
import LoginDialog from "./LoginDialog.vue"

export default {
  components: {
    'ml-login': LoginDialog,
    'ml-navbar': Navbar,
    'ml-sidebar': Sidebar,
    'ml-footer': Footer,
    'ml-controlsidebar': ControlSidebar,
  },
  data() {

    let page_code = this.$route.path;

    if (this.$microlink.pages[page_code]) {

      /*
       * 將 this.$microlink.pages[page_code] clone 一份給 this.page
       * 不可以直接 this.page = this.$microlink.pages[page_code]
       * 否則會導致 this.page 與 this.$microlink.pages[page_code] 指向同一個記憶體位置
       * 這樣在切換 widget 時會導致 this.$microlink.pages[page_code] 也跟著變動
       */
      this.page = JSON.parse(JSON.stringify(this.$microlink.pages[page_code]));

      for (var key in this.page.widgets) {


        if (this.$microlink.widgets[this.page.widgets[key].widget] == undefined) {
          continue;
        }

        let widget_type = this.$microlink.widgets[this.page.widgets[key].widget].widget.type;
        if (this.$microlink.widgetComponents[widget_type] != undefined) {

          // 組件類型
          this.page.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];

          // 操作記錄
          this.page.widgets[key].history = [];
        }
      }

      return {
        title: this.page.title,
        widgets: this.page.widgets
      }
    }
    else {

      // 之後想辦法導到 404
      return { title: '', widgets: [] }
    }
  },
  methods: {
    /**
     * 切換組件
     * @param {*} key 組件陣列的索引值
     * @param {*} code 組件代碼
     * @param {*} params 組件參數
     * @param {*} type 組件類型
     */
    switch_widget: function (key, code, params, widget_type) {

      // 組件資訊
      let widget_info = this.$microlink.widgets[code];

      if (widget_type == undefined) {
        widget_type = widget_info.widget.type;
      }

      if (this.$microlink.widgetComponents[widget_type] != undefined) {
        // 指定組件代碼給 widgets
        this.widgets[key].widget = widget_info.code;
        this.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];
        this.widgets[key].params = (params == undefined) ? {} : params;
        this.$forceUpdate();
      }

    },
    // 組件歷程事件
    widget_event: function ($event, key) {

      let action = $event.event;
      delete $event.event;

      // 儲存歷程
      if (action == 'save') {
        delete $event.event;
        if (this.widgets[key].history.length > 0) {
          if (JSON.stringify(this.widgets[key].history[this.widgets[key].history.length - 1]) == JSON.stringify($event)) {
            return;
          }
        }

        // clone 一份 $event 給 history
        this.widgets[key].history.push(JSON.parse(JSON.stringify($event)));
      }
      // 回上一步
      else if (action == 'back') {
        if (this.widgets[key].history.length == 1) {
          return;
        }

        // 倒退幾步
        for (let i = 0; i < $event.step; i++) {
          this.widgets[key].history.pop();
        }
        let last_params = this.widgets[key].history[this.widgets[key].history.length - 1];

        // 刪除上一步的歷程，如果剩最後一筆，代表是根目錄了
        // 如果 last_params.params 中仍含有 _sr_ 參數則刪除
        if (this.widgets[key].history.length == 1) {
          if (last_params.params._sr_ != undefined) {
            delete last_params.params._sr_;
          }
        }

        let code = last_params.code;
        let params = last_params.params;
        let type = last_params.type;

        // 麵包屑標題處理
        params.breadcrumb_title = last_params.title;

        this.switch_widget(key, code, params, type);
      }
      else if (action == 'switch') {
        if ($event.type == undefined) {
          $event.type = this.$microlink.widgets[$event.code].widget.type;
        }
        this.switch_widget(key, $event.code, $event.params, $event.type);
      }
    }
  }
}
</script>

<style></style>