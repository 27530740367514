<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img v-show="logo != ''" v-bind:src="logo" v-bind:alt="this.$microlink.information.title"
        class="brand-image img-circle elevation-3" style="opacity: .8">
      <div class="no-logo-space" v-show="logo == ''"></div>
      <span class="brand-text font-weight-light">{{ this.$microlink.information.title }}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <div v-for="widget in widgets" :key="widget.id"
        v-bind:class="['col-' + widget.mobile, 'col-sm-' + widget.pad, 'col-md-' + widget.pad, 'col-lg-' + widget.desktop]">
        <component v-bind:is="widget.widgetComponent" v-bind:widget="widget" :key="Math.random()"></component>
      </div>
      <SidebarMenu></SidebarMenu>
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import SidebarMenu from "../components/sidebar-menu/SidebarMenu.vue"
export default {
  props: ['webTitle'],
  components: {
    SidebarMenu
  },
  data() {
    let logo = '';
    if (this.$microlink.information.logo != undefined && this.$microlink.information.logo != '') {
      if (this.$microlink.information.logo_base_url != undefined && this.$microlink.information.logo_base_url != '') {
        logo = this.$microlink.information.logo_base_url + this.$microlink.information.logo;
      }
      else {
        logo = this.$microlink.information.logo;
      }
    }

    for (var key in this.$microlink.sides.leftside.widgets) {
      if (this.$microlink.widgets[this.$microlink.sides.leftside.widgets[key].widget] == undefined) {
        continue;
      }

      let widget_type = this.$microlink.widgets[this.$microlink.sides.leftside.widgets[key].widget].widget.type;
      if (this.$microlink.widgetComponents[widget_type] != undefined) {
        // 組件類型
        this.$microlink.sides.leftside.widgets[key].widgetComponent = this.$microlink.widgetComponents[widget_type];
        // 操作記錄
        this.$microlink.sides.leftside.widgets[key].history = [];
      }
    }

    return {
      logo: logo,
      system_title: this.$microlink.information.title,
      widgets: this.$microlink.sides.leftside.widgets
    }
  }
}
</script>

<style scoped>
.no-logo-space {
  display: inline;
  margin-left: 20px;
}
</style>