<template>
  <li class="nav-item" v-bind:class="{ 'active': item.active }">
    <template v-if="item.subitems && item.subitems.length > 0">
      <a href="javascript:void(0);" class="nav-link">
        <i class="nav-icon"
          v-bind:class="((item.icon !== '') ? (item.icon === 'none' ? 'space-icon' : item.icon) : 'fas fa-list')"></i>
        <p>
          {{ item.title }}
          <i class="right fas fa-angle-left"></i>
        </p>
      </a>
      <ul class="nav nav-treeview">
        <Item v-for="subitem in item.subitems" v-bind:key="subitem" v-bind:item="subitem">{{ subitem }}</Item>
      </ul>
    </template>
    <template v-else>
      <router-link v-bind:to="item.path" class="nav-link">
        <i class="nav-icon"
          v-bind:class="((item.icon !== '') ? (item.icon === 'none' ? 'space-icon' : item.icon) : 'fas fa-list')"></i>
        <p>
          {{ item.title }}
        </p>
      </router-link>
    </template>
  </li>
</template>

<script>
import Item from "./SidebarMenu-Item.vue"
export default {
  name: 'Item',
  props: ['item'],
  components: {
    Item
  }
}
</script>

<style scoped>
.nav-icon.space-icon {
  margin-left: 25px !important;
}

.router-link-active {
  background-color: rgba(255, 255, 255, .1);
  color: #fff;
}

/*
ul.nav-treeview li {
  font-size: 12px;
}
*/
</style>